<template>
  <div class="group" :class="[size]">
    <input v-if='mutType === "tel"' :class="[size]" :type="mutType" onfocus="this.removeAttribute('readonly');" autocorrect="off" spellcheck="false"
           :value="value" @input="changeValue($event.target.value,$event)" @paste="onPaste" required v-mask="'+7(###)###-##-##'">
    <input v-else :type="mutType" :placeholder="placeholder" :class="[size]" :value="value" @paste="onPaste" @input="changeValue($event.target.value,$event)">

  </div>
</template>

<script>

import { mask } from 'vue-the-mask'

export default {
  name: "InputProfile",
  props:{
    placeholder:String,
    size: String,
    value: String,
    type: String,
    isTrimEnable : Boolean,
    isRussianSymbolsDisabled: Boolean,
    iconClass: String,
    textClass: String


  },
  directives: {
    mask
  },
  watch:{
    type:{
      immediate:true,
      deep: true,
      handler(newVal){
        this.mutType = newVal
      }
    }
  },
  data(){
    return {
      isShowPassword: false,
      mutType : 'input'
    }
  },
  methods: {
    changeValue(value,ev) {
      if (this.isTrimEnable) {
        value = value.replace(/ /g,'')
      }
      if (this.isRussianSymbolsDisabled){
        value = value.replace(/[^a-zA-Z0-9]+/gm,'')
      }
      ev.target.value = value
      console.log(value)
      this.$emit('input', value)
    },

    onPaste(e){
      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();

      // Get pasted data via clipboard API
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text').trim();
      this.changeValue(pastedData,e)
    },
  }
}

</script>

<style scoped lang="scss">
  input{
    border: 0;
    outline: 0;
  }

  .group{
    background: #FFFFFF;
    border: 1px solid #E2E5E9;
    border-radius: 8px;
  }

  .lg{
    width: 305px;
    height: 46px;
    border-radius: 8px;
    input{
      padding-left: 20px;
      width: 270px;
      height: 44px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      font-size: 14px;
      color: #333942;
      letter-spacing: 1px;

      &::placeholder{
        color: #909090;
      }

      &:focus::placeholder{
        color: transparent;
      }
      &:focus::-webkit-input-placeholder { color:transparent; }
      &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
      &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
      &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    }
  }

  .sm{
    width: 46px;
    height: 46px;
    border-radius: 8px;


    input{
      width: 44px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      font-size: 14px;
      color: #333942;
      letter-spacing: 1px;
      text-align: center;
      height: 44px ;

      &::placeholder{
        color: #909090;
      }

      &:focus::placeholder{
        color: transparent;
      }
      &:focus::-webkit-input-placeholder { color:transparent; }
      &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
      &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
      &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    }

  }
</style>
